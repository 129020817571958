import React from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import {HomeComponent} from "./components/home";
import {NotFoundComponent} from "./components/404";

function App() {
    return (
        <Routes>
            <Route path="/" element={<HomeComponent/>}/>
            <Route path="*" element={<NotFoundComponent/>}/>
        </Routes>
    )
}

export default App;
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGithub, faLinkedin} from "@fortawesome/free-brands-svg-icons";
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";

export function HomeComponent() {
    const email = 'adrian@adriankeenan.co.uk';
    const [showEmail, setShowEmail] = useState(false);
    return (
        <section id="content-main">
            <h1>Adrian Keenan</h1>
            <h2>Software Developer in Hampshire</h2>
            <ul>
                <li>
                    <a href="https://github.com/adriankeenan/" title="GitHub">
                        <FontAwesomeIcon icon={faGithub}/>
                        <i className="fab fa-github" aria-hidden="true"></i>
                    </a>
                </li>
                <li>
                    <a href="https://www.linkedin.com/in/adriankeenandev" title="LinkedIn">
                        <FontAwesomeIcon icon={faLinkedin}/>
                        <i className="fab fa-linkedin" aria-hidden="true"></i>
                    </a>
                </li>
                <li>
                    <a href="/contact" title="Contact" id="href-contact" onClick={(e) => {
                        e.preventDefault();
                        setShowEmail(true);
                    }}>
                        <FontAwesomeIcon icon={faPaperPlane}/>
                        <i className="fas fa-paper-plane" aria-hidden="true"></i>
                    </a>
                </li>
            </ul>
            {showEmail && <p id="txt-contact">Please send an email to <a href={`mailto:${email}`}>{email}</a></p>}
        </section>
    );
}